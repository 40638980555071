<template>
  <div>
    <div class="bg-white">
      <OtherLoading variant="warning" />
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
export default {
  data() {
    return {
      sessionId: ""
    };
  },
  components: {
    OtherLoading
  },

  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      this.$liff
        .init({ liffId: this.$liff_Main_ID_Kerry_Friend })
        .then(() => {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },

  created() {
    this.sessionId = this.$route.query.sessionId;
    this.getUserData();
  },

  methods: {
    getUserData: async function () {

      await this.$axios
        .get(this.$baseUrl + "/user/" + this.sessionId)
        .then(response => {
          if (response.data.result === 1) {
            let getKerryFriend = this.$route.query.isKerryFriend || false

            getKerryFriend = getKerryFriend == 'true' ? true : false

            if (getKerryFriend) {
              if (!response.data.detail.isRegistered) {
                this.$router.replace(`/login/kerryfriend/th?sessionId=${this.sessionId}`)
                return
              }
            } else {
              if (!response.data.detail.memberToken) {
                this.$router.replace(`/login/th?sessionId=${this.sessionId}`)
                return
              }
            }
            
            if (response.data.detail.userGUID) {
              this.getAuditLink(response.data.detail);
            } else {
              this.$router.push("/session-expired");
            }
          }
        })
        .catch(err => {
          this.$router.push("/session-expired");
          console.log(err);
        });
    },
    getAuditLink(value) {
      let { userGUID } = value;

      this.$axios
        .get(`${this.$baseUrl}/kerryapi/KerryFriend/poc/authen/${userGUID}`)
        .then(response => {
          if (response.data.result == 1) {
            window.location.href = response.data.detail.url;
          } else {
            this.$router.push("/session-expired");
          }
        })
        .catch(err => {
          this.$router.push("/session-expired");
          console.log(err);
        });
    }
  }
};
</script>

<style scoped></style>
